body {
  background-color: #28cccc;
}

.App {
  text-align: center;
  font-size: calc(10px + 4vmin);
  color: white;
  font-family: 'SF Display';
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#root {
  height: 100vh;
}


p {
  font-weight: light;
}

h1 {
  margin: 0
}

.App-logo {
  height: 25vh;
  margin-top: auto;
  padding: 0;
  pointer-events: none;
  -webkit-filter: drop-shadow( 0px 0px 2px rgba(0, 0, 0, .5));
  filter: drop-shadow( 0px 0px 3px rgba(0, 0, 0, .5));
}

.App-caption {
  margin: auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
