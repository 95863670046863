body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'SF Display';
  src: url('font/SFDisplay-Thin.eot');
  src: url('font/SFDisplay-Thin.eot?#iefix') format('embedded-opentype'),
      url('font/SFDisplay-Thin.woff2') format('woff2'),
      url('font/SFDisplay-Thin.woff') format('woff'),
      url('font/SFDisplay-Thin.ttf') format('truetype'),
      url('font/SFDisplay-Thin.svg#SFDisplay-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}